<template>
    <div class="main-container">
      <h5 class="pageTitle">접속기록 조회</h5>
  
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <select v-model="searchCriteria" class="search-select">
              <option value="username">이름</option>
              <option value="userid">아이디</option>
              <option value="user_type">사용자타입</option>
              <option value="workAt">작업일시</option>
            </select>
            <input
              v-model="searchQuery"
              type="text"
              :placeholder="`검색할 내용을 입력하세요`"
              class="search-input"
              @input="filterResults"
            />
            <button class="btn-search" @click="filterResults">검색</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
  
      <!-- 로그 테이블 -->
      <table>
        <thead>
          <tr>
            <th>NO.</th>
            <th>사용자ID</th>
            <th>사용자명</th>
            <th>사용자타입</th>
            <th>작업명</th>
            <th>작업일시</th>
            <th>접속일시</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedData.length === 0">
            <td colspan="7">데이터가 없습니다.</td>
          </tr>
          <tr
            v-for="(log, index) in paginatedData"
            :key="log.uuid"
            :class="{ 'alt-row': index % 2 === 1 }"
          >
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ log.user_ID }}</td>
            <td>{{ log.admin?.name }}</td>
            <td>{{ typeMapping[log.user_type] || ''  }}</td>
            <td>{{ log.workCode?.name }}</td>
            <td>{{ formatDateTime(log.workAt) }}</td>
            <td>{{ formatDateTime(log.conectAt) }}</td>
          </tr>
        </tbody>
      </table>
  
      <!-- 페이지네이션 -->
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ pageCount }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
      </div>
    </div>
  </template>


<script>
import axios from "@/axios"; // 필요시 수정
import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
export default {
    name:"accessLog",
    components: {
      mdbContainer,   
      mdbRow,
      mdbCol
    },
  data() {
    return {
      searchQuery: "",
      searchCriteria: "username",
      logs: [], // 전체 로그 데이터
      filteredResults: [],
      currentPage: 1,
      perPage: 10,
      typeMapping: {
        OPERATOR: "운영자",
        INSTRUCTOR: "교관",
        DIRECTOR: "원장",
      },
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.filteredResults.length / this.perPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredResults.slice(start, start + this.perPage);
    },
  },
  methods: {
    async fetchLogs() {
    const token = JSON.parse(sessionStorage.getItem('token'))?.access_token;
      try {
        const res = await axios.get("/access-log",{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        this.logs = res.data;
        //console.log(res.data);
        this.filteredResults = this.logs;
        
      } catch (err) {
        console.error("로그 데이터를 불러오는 중 오류:", err);
      }
    },
    formatDateTime(datetime) {
      return new Date(datetime).toLocaleString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    filterResults() {
        const keyword = this.searchQuery.trim().toLowerCase();

        if (!keyword) {
            this.filteredResults = this.logs;
            return;
        }

        const fieldMapper = {
            username: (log) => log.admin?.name || "",
            userid: (log) => log.user_ID || "",
            user_type: (log) => log.user_type || "",
            workAt: (log) => log.workAt || "",
        };

        const getter = fieldMapper[this.searchCriteria];

        this.filteredResults = this.logs.filter((log) => {
            const value = getter ? getter(log).toString().toLowerCase() : "";
            return value.includes(keyword);
        });

        this.currentPage = 1;
    },

    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.pageCount) this.currentPage++;
    },
  },
  mounted() {
    this.fetchLogs();
  },
};
</script>


  <style scoped>
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .search-container {
    padding: 25px;
 
  }
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 오른쪽 정렬 */
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px; /* 각 요소 사이에 간격 추가 */
  }
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }

  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-right: 20px; /* 검색 버튼과 등록 버튼 사이의 여백 */
    /*cursor: pointer;
     transition: background-color 0.3s ease; */
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-left: auto; /* 등록 버튼을 오른쪽 끝으로 정렬 */
    align-self: flex-end; /* 등록 버튼을 아래쪽으로 정렬 */
    }
  
    table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;  
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  .alt-row {
    background-color: #f9f9f9;
  }
  .clickable-row {
    cursor: pointer;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
 
  </style>
  